import React from 'react';
import { useHistory } from "react-router-dom";

import { canModify } from "./util/index";

import './App.css';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloConsumer } from '@apollo/react-common'
import gql from "graphql-tag";

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '@material-ui/lab/Alert';

// Page-specific styling
// The domains table should have a minimum size
const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
  infoBox: {
    marginTop: 25,
    marginBottom: 25
  }
});

// GraphQL query to get all domains by pattern
const READ_DOMAINS = gql`
  query domains($Pattern: String!){
    domains(pattern: $Pattern) {
      hostname,
      rules {
        name
      }
    }
  }
`;

// GraphQL mutation to add Domain
const ADD_DOMAIN = gql`
  mutation SetupDomain($name: String!) {
    setupDomain(name: $name, rules: []) {hostname}
  }
`;

/**
 * Main Overview page
 */
function Overview() {
  var apolloClient;

  // Filter field to reduce the amount of results
  const [domainFilter, setDomainFilter] = React.useState("*");
  var filterTimeoutID;
  var newFilterValue;

  // Bind the GraphQL queries and mutations to Apollo React
  const { data, loading, error } = useQuery(READ_DOMAINS, { variables: { Pattern: domainFilter },fetchPolicy:"cache-and-network"});
  const [ addDomain ] = useMutation(ADD_DOMAIN);

  // Apply page-specific styling
  const classes = useStyles();

  // For navigation
  let history = useHistory();

  // The in-page dialogs and their fields
  const [isOpenAddDomainDialog, setOpenAddDomainDialog] = React.useState(false);
  const [newDomainName, setNewDomainName] = React.useState("New Domain");

  const openAddDomainDialog = () => {
    setOpenAddDomainDialog(true);
  };

  const handleCloseAddDomainDialog = () => {
    setOpenAddDomainDialog(false);
  };

  const handleAddDomain = () => {
      //validate https://,http:// protocols,validation for forwardslash,'/'are not allowed.
      if ((newDomainName.toLowerCase().includes("https://")) || newDomainName.toLowerCase().includes("http://") || (newDomainName.indexOf("/") > -1) ) {
        alert("Provide valid domain name without protocols (http or https)");
        return;
      }  
      
      //added new additional validation (line 112,113) to check if domain name has sepcial characters, length >67 .
    if( !newDomainName.match(/^(?!:\/\/)(?=.{1,255}$)((.{1,63}\.){1,127}(?![0-9]*$)[a-z0-9-]+\.?)$/) ||
        (!newDomainName.match(/^[a-zA-Z\d-.]+$/)) ){
      alert("Please enter valid FQDN")
      return
    }

    //send pattern '*' to check for duplicate domain.
    apolloClient.query({query: READ_DOMAINS, variables: { Pattern: '*'}}).then(result => {
      if(result.data.domains.length > 0 && result.data.domains.filter((e)=>e.hostname===newDomainName).length>0  )  {    
        alert("Such a domain already exists!");
      }
      else {
        return addDomain({
            variables: {
              name: newDomainName,
            }
          }).then( () => {
            // refetch();

            // Navigate to the newly created domain
            history.push("/domain/" + newDomainName);
          })
      }
    })
  }


  if (loading) return <p>loading...</p>;
  if (error) return <p>ERROR during API query. Please contact the support team.</p>;
  if (!data) return <p>Not found</p>;


  return (
    <Container maxWidth="lg">
      <ApolloConsumer>
        { client => {
            // Pull the Apollo client out of react and make it usable for direct GraphQL queries
            apolloClient = client
          }
        }
      </ApolloConsumer>
      <Alert icon={false} severity="info" className={classes.infoBox} style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
        <p>
          <b>PILOT PHASE - Please handle with care!</b>
        </p>
      </Alert>
      <Alert icon={false} severity="success" className={classes.infoBox} >
        <p>
          Use this tool to manage any domain that does not have own content,
          but should only redirect to another domain. We call these &quot;Vanity Domains&quot;.
        </p>
        <p>
          A typical use case for vanity domains is the redirect of non-www to www.
        </p>
      </Alert>
      <Alert severity="warning" className={classes.infoBox}>
          <b>ATTENTION: Mode change! SSL now needs to be enabled explicitly via Buttons on the Domain details page.</b>
      </Alert>
      { !canModify() && 
        <Alert icon={false} severity="warning" className={classes.infoBox} >
        <p>
          You seem to have view permissions only. You can only search and inspect the domains that are managed via the Redirector. 
        </p>
        <p>
          To get something changed, e.g. a new Domain, or some rule change - please reach out to
          the support team via the <a href="https://support.baywsf.com/helpdesk/Tickets/New?categoryId=421034" target="blank">Website Factory Helpdesk</a>, using the 
          &quot;Vanity Domain Redirect setup&quot; category.
        </p>
      </Alert>
      }
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <TextField 
            variant="filled" 
            fullWidth
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            defaultValue={domainFilter}
            onChange = {event => { 
              newFilterValue = event.target.value;
              if( filterTimeoutID ) {
                window.clearTimeout(filterTimeoutID);
              }
              
              filterTimeoutID = window.setTimeout(() => {
                setDomainFilter(newFilterValue);
              }, 500);
            }}
          />
        </Grid>
        <Grid item xs={2} align="right">
          { canModify() && 
            <Button variant="contained" color="primary" onClick={openAddDomainDialog}>Add Domain</Button>
          }
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>No. of Redirect Rules</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.domains.map((domain) => (
              <TableRow key={domain.hostname}>
                <TableCell component="th" scope="row">
                  {domain.hostname}
                </TableCell>
                <TableCell>{domain.rules.length}</TableCell>
                <TableCell align="right">
                  <Button variant="contained" color="primary" component={Link} to={"/domain/" + domain.hostname}>
                      {canModify() ? "Edit" : "View"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isOpenAddDomainDialog} onClose={handleCloseAddDomainDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Domain</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add another Domain, please enter the domain name below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Domain hostname"
            fullWidth
            defaultValue = { newDomainName }
            onChange = {event => setNewDomainName(event.target.value)}
            onFocus = {(event) => event.target.select()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDomainDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddDomain} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Overview;
